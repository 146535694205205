import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: '8043687e-9cdd-4e6c-8590-4fddecc9cce2',
    authority: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22',
    redirectUri: 'https://awsivrpru.ciostage.accenture.com', // Replace with your redirect URI
    responseType: 'token',
    scopes: [
      'openid'
    ]
  },

  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [],
};
