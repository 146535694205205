import React from 'react';
import { Card } from "antd";
import ReportDropdown from "./components/reportsDropdown/reportDropdown";
import TabReportType from "./components/tabReportType/tabReportType";
import './App.css';
import { useConnectionManager } from "./components/provider/connectionManagerProvider";
import { loginRequest} from './config';
import {AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import {MsalProvider} from '@azure/msal-react';


const MainContent =()=>{
  const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const {reportType} = useConnectionManager()

    let titleName



    if (reportType === "globalSolution"){
      titleName = "Global Password Reset and Unlock"
    } else{
      titleName = "India Password Reset and Unlock"
    }

    const handleRedirect = () => {
      instance
          .loginRedirect({
              ...loginRequest,
              prompt: 'create',
          })
          .catch((error) => console.log(error));
  };


  return (
    <div className="App">
        <AuthenticatedTemplate>
            {activeAccount ? (
                        <>
                          <div className="pru-header">
                            <ReportDropdown/>
                          </div>
                          <Card title={<h1>{titleName}</h1>} bordered={false}>
                            <TabReportType/>
                          </Card>
                        </>
             ) : handleRedirect()}
         </AuthenticatedTemplate>
    </div>
    
);
}



const App = ({ instance }) => {

  return (
    <MsalProvider instance={instance} >
      <MainContent/>
    </MsalProvider>
      
  );
}

export default App;
